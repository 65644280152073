import React from "react";
import "./landing.css";
import logo from "../assets/logos/3vo-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram, faDiscord } from "@fortawesome/free-brands-svg-icons";

const Landing = () => {
  return (
    <>
      <div className={`landing`}>
        <div className="landing-wrapper">
          <div className="landing-main">
            <div className="landing-logo">
              <img src={logo} alt=""></img>
            </div>
            <div className="landing-title-containter-main">
              <div className="landing-title-bold">3VO</div>
              <div className="landing-title-special-bold">SuperApp</div>
            </div>
            <div className="landing-title-containter">
              <div className="landing-title">Stay Tuned ..</div>
            </div>{" "}
            <div className="landing-title-containter">
              <div className="landing-subtitle">Join Our Community </div>
            </div>
            <div className="landing-buttons">
              <a
                href="https://t.me/portal_3vo_me"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTelegram}></FontAwesomeIcon>
              </a>

              <a
                href="https://discord.com/invite/Bd5jZ7PPUn"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord}></FontAwesomeIcon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
